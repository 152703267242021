import { useEffect, useState } from "react";
import Footer from "../Layout/Footer";
import AgentHeader from "../Layout/Header";
import APIConfig from "../Configuration/APIConfig";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import PageLoader from "../Layout/PageLoader";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
const initialFieldValues = {
    umrahEnquiryId: "00000000-0000-0000-0000-000000000000",
    umrahPackageId: "00000000-0000-0000-0000-000000000000",
    umrahPriceId: "00000000-0000-0000-0000-000000000000",
    name: "",
    email: "",
    mobile: "",
    status: "NEW",
    adults: 0,
    kids: 0,
    infants: 0,
    comments: "",
    nationality: "",
    profilePhotoFile: null,
    passportFrontPageFile: null,
    passportBackPageFile: null,
    aadharCardFile: null,
    panCardFile: null,
    vaccinationCardFile: null,
    profilePhotoSrc: '/assets/images/avatar.png',
    passportFrontPageSrc: '/assets/images/doc_bgc.png',
    passportBackPageSrc: '/assets/images/doc_bgc.png',
    panCardSrc: '/assets/images/doc_bgc.png',
    aadharCardSrc: '/assets/images/doc_bgc.png',
    vaccinationCardSrc: '/assets/images/doc_bgc.png',
};
export default function HajjPackage() {
    return (
        <div id="main-wrapper">
            <AgentHeader></AgentHeader>
            <div className="clearfix"></div>
            <div className="py-2 gray-simple position-relative">
                <div className="container">
                    {/* Search Form */}
                    <div className="row justify-content-center align-items-center">
                        <div className="col-xl-12 col-lg-12 col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><a href="#" className="text-primary">Home</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Hajj Package</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <section className="pt-3">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12">
                            <div className="card border-0 p-3 mb-4">
                                <div className="crd-heaader d-md-flex align-items-center justify-content-between">
                                    <div className="crd-heaader-first">
                                        <div className="d-block">
                                            <h4 className="mb-0">Hajj from Hyderabad</h4>
                                            <div className="exlops">
                                                <p className="detail ellipsis-container fw-semibold">
                                                    <span className="ellipsis-item__normal">38 Days Hajj Package</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12">
                            <div className="row">
                                {/* Details */}
                                <div className="col-xl-12">
                                    <div className="mb-lg-0 mb-3">
                                        <div className="relative d-flex">
                                            <img src="/assets/images/slider/slider1.jpg" style={{ width: "100%", maxHeight: "400px" }} alt="image" className="rounded-2 img-fluid" />
                                        </div>
                                    </div>
                                    <div className="tab-content" id="pillstour-tabContent">
                                        {/* Overview Info */}
                                        <div className="tab-pane fade show active" id="pills-overview" role="tabpanel" aria-labelledby="pills-overview-tab" tabIndex={0}>
                                            <div className="overview-wrap full-width">
                                                <div className="card border rounded-3 mb-4">
                                                    <div className="card-header">
                                                        <h4 className="fs-5">Hajj Package Details </h4>
                                                        <div className="flts-flex-end">
                                                            <div className="row align-items-center justify-content-end gx-2">
                                                                <div className="col-auto">
                                                                    <a href="https://wa.me/919849787154" rel="noopener noreferrer" target="_blank" className="btn btn-success btn-md full-width fw-medium"><i className="fab fa-whatsapp me-2"></i>Chat Now</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="expott-info mb-4">
                                                            <h5>Inclusions</h5>
                                                            <ul className="row align-items-center p-0 g-3">
                                                                <li className="col-md-12">
                                                                    <i className="fa-regular fa-circle-dot text-success me-2" />Tentative Start Date : 30 or 31 May 2025
                                                                </li>
                                                                <li className="col-md-12">
                                                                    <i className="fa-regular fa-circle-dot text-success me-2" />Mina Tent Mohallim D Zone 3 (Old Mina) Subject to SAUDI GOVT LAW
                                                                </li>
                                                                <li className="col-md-12">
                                                                    <i className="fa-regular fa-circle-dot text-success me-2" /> Seat by seat private bus for 5Days of HAJ. Subject to SAUDI GOVT LAW
                                                                </li>
                                                                <li className="col-md-12">
                                                                    <i className="fa-regular fa-circle-dot text-success me-2" /> Makkah Hotel Haram stay for 11 Days with catering food. 4 or 5 persons per room
                                                                </li>
                                                                <li className="col-md-12">
                                                                    <i className="fa-regular fa-circle-dot text-success me-2" /> Madina Hotel stay for 11 Days with catering food. 4 or 5 persons per room
                                                                </li>
                                                                <li className="col-md-12">
                                                                    <i className="fa-regular fa-circle-dot text-success me-2" />Makkah and Madina ziyarat by AC sharing bus
                                                                </li>
                                                                <li className="col-md-12">
                                                                    <i className="fa-regular fa-circle-dot text-success me-2" />20" Trolley Bag
                                                                </li>
                                                                <li className="col-md-12">
                                                                    <i className="fa-regular fa-circle-dot text-success me-2" />5ltrs Zam Zam water bottle complimentary
                                                                </li>
                                                                <li className="col-md-12">
                                                                    <i className="fa-regular fa-circle-dot text-success me-2" />Complimentary laundry service
                                                                </li>
                                                                <li className="col-md-12">
                                                                    <i className="fa-regular fa-circle-dot text-success me-2" />3 Times meal NonVeg or Veg food, milk, fruits, tea, coffee, pastry and pepsi are available
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card border rounded-3 mb-4">
                                                    <div className="card-header">
                                                        <h4 className="fs-5">VIP Package from Hyderabad </h4>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="expott-info mb-4">
                                                            <ul className="row align-items-center p-0 g-3">
                                                                <li className="col-md-12">
                                                                    <i className="fa-regular fa-circle-dot text-success me-2" />Price 6,10,000 INR + Taxes
                                                                </li>
                                                                <li className="col-md-12">
                                                                    <i className="fa-regular fa-circle-dot text-success me-2" />Cheque 5,00,000 INR
                                                                </li>
                                                                <li className="col-md-12">
                                                                    <i className="fa-regular fa-circle-dot text-success me-2" /> Cash 1,10,000 INR
                                                                </li>
                                                                <li className="col-md-12">
                                                                    <i className="fa-regular fa-circle-dot text-success me-2" /> Taxes on cheque amount 50,000 INR, 5% GST, 5% TCS
                                                                </li>
                                                                <li className="col-md-12">
                                                                    <i className="fa-regular fa-circle-dot text-success me-2" /> GST NON-Refundable, TCS Refundable
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card border rounded-3 mb-4">
                                                    <div className="card-header">
                                                        <h4 className="fs-5">Hajj Package from Hyderabad </h4>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="expott-info mb-4">
                                                            <ul className="row align-items-center p-0 g-3">
                                                                <li className="col-md-12">
                                                                    <i className="fa-regular fa-circle-dot text-success me-2" />Price 5,80,000 INR + Taxes
                                                                </li>
                                                                <li className="col-md-12">
                                                                    <i className="fa-regular fa-circle-dot text-success me-2" />Cheque 5,00,000 INR
                                                                </li>
                                                                <li className="col-md-12">
                                                                    <i className="fa-regular fa-circle-dot text-success me-2" /> Cash 80,000 INR
                                                                </li>
                                                                <li className="col-md-12">
                                                                    <i className="fa-regular fa-circle-dot text-success me-2" /> Taxes on cheque amount 50,000 INR, 5% GST, 5% TCS
                                                                </li>
                                                                <li className="col-md-12">
                                                                    <i className="fa-regular fa-circle-dot text-success me-2" /> GST NON-Refundable, TCS Refundable
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="flts-flex-end">
                                                            <div className="row align-items-center justify-content-end gx-2">
                                                                <div className="col-auto">
                                                                    <a href="https://wa.me/919849787154" rel="noopener noreferrer" target="_blank" className="btn btn-success btn-md full-width fw-medium"><i className="fab fa-whatsapp me-2"></i>Chat Now</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer></Footer>
        </div>
    )
}