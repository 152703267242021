import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import config from '../Configuration/APIConfig';
import axios from "axios";
import auth from "../Authentication/Auth";
import HeaderHome from "../Layout/HeaderHome";
import Footer from "../Layout/Footer";
import HomeBanner from "../Home/HomeBanner";
const initialAgentLoginValues = {
    email: '',
    password: ''
};
export default function AgentLogin(props) {
    const navigate = useNavigate();
    const [agentLoginValues, setAgentLoginValues] = useState(initialAgentLoginValues);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState("");
    const applicationAPI = (url = config.APIACTIVATEURL + config.LOGINAGENT) => {
        return {
            agentlogin: (newRecord) => axios.post(url, newRecord)
        };
    };
    const handleAgentLoginInputChange = (e) => {
        const { name, value } = e.target;
        setAgentLoginValues({
            ...agentLoginValues,
            [name]: value,
        });
    };
    const agentLoginValidate = () => {
        let temp = {};
        temp.email = agentLoginValues.email === '' ? false : true;
        temp.password = agentLoginValues.password === '' ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x == true);
    };
    const handleAgentLoginSubmit = (e) => {
        e.preventDefault();
        if (agentLoginValidate()) {
            try {
                setLoading(true);
                const formData = new FormData()
                formData.append("email", agentLoginValues.email)
                formData.append("password", agentLoginValues.password)
                formData.append("usertype", "AGENT")
                checkUser(formData);
            } catch (err) {
                setLoading(false);
                setStatus('Please check the mandatory fields');
            }
        } else {
            setLoading(false);
            setStatus('Please check the mandatory fields');
        }
    };
    const checkUser = (loginData) => {
        applicationAPI()
            .agentlogin(loginData)
            .then((res) => {
                if (res.data.response.succeeded === true) {
                    setLoading(true);
                    resetForm();
                    auth.ulogin(() => {
                        localStorage.setItem('userId', res.data.response.data.userId);
                        localStorage.setItem('country', res.data.response.data.country)
                        localStorage.setItem('roleName', res.data.response.data.role);
                        localStorage.setItem('userToken', res.data.response.data.token);
                        localStorage.setItem('currency', res.data.response.data.currency);
                        localStorage.setItem('tokenexpiration', res.data.response.data.expiration);
                        navigate('/searchflights')
                    });
                } else {
                    setStatus(res.data.response.message);
                    setLoading(false);
                }
            })
            .catch(function (error) {
                setStatus(error);
                setLoading(false);
            });
    };
    const applyErrorClass = (field) => field in errors && errors[field] == false ? ' form-control-danger' : '';
    const resetForm = () => {
        setAgentLoginValues(initialAgentLoginValues);
        setStatus('');
    }
    useEffect(() => {
        if (localStorage.getItem('userId') === null) {
            localStorage.clear();
            navigate("/");
        }
        else if (localStorage.getItem('roleName') === null) {
            navigate("/");
        }
        else if (localStorage.getItem('roleName') !== "AGENT") {
            navigate("/");
        }
        else {
            navigate("/searchflights");
        }
    }, [])
    return (
        <div id="main-wrapper" style={{backgroundColor:"#5A154A"}}>
            <HeaderHome></HeaderHome>
            <div className="clearfix"></div>
            <div className="container mt-5">
                <div className="row align-items-center">
                    {/* Left Section */}
                    <div className="col-md-6">
                        <div className="main-banner">
                            <h1>Empowering the Future of Travel Booking</h1>
                            <p>One stop solution for your travel business</p>
                            <div class="vector-images">
                                <span><i className="fa fa-plane" /></span>
                                <span><i className="fa fa-building" /></span>
                                <span><i className="fa fa-umbrella-beach" /></span>
                            </div>
                        </div>
                    </div>
                    {/* Right Section */}
                    <div className="col-md-5 offset-md-1">
                        <div className="login-card">
                            <h5>AGENT LOGIN</h5>
                            {status !== "" ? <div class="alert alert-info shadow-md"> <i class="fas fa-check-circle"></i> {status} </div> : ""}
                            <form onSubmit={handleAgentLoginSubmit} autoComplete="off" noValidate className="text-start">
                                <div className="mb-3 position-relative">
                                    <div className="form-floating mb-4">
                                        <input className={'form-control' + applyErrorClass('email')} name="email" type="text" value={agentLoginValues.email} onChange={handleAgentLoginInputChange} placeholder="Enter Email" />
                                        {errors.email === false ? (<div className="validationerror"> Please enter email </div>) : ('')}
                                        <label htmlFor="username">Email</label>
                                    </div>
                                </div>
                                <div className="mb-3 position-relative">
                                    <div className="form-floating mb-4">
                                        <input className={'form-control' + applyErrorClass('password')} id="password-field" name="password" type="password" value={agentLoginValues.password} onChange={handleAgentLoginInputChange} placeholder="Enter Password" />
                                        {errors.password === false ? (<div className="validationerror"> Please enter password </div>) : ('')}
                                        <label htmlFor="password">Password</label>
                                    </div>
                                </div>
                                <div className="mb-3 form-check">
                                    <input type="checkbox" className="form-check-input" id="rememberMe" />
                                    <label className="form-check-label remember-me" htmlFor="rememberMe">Remember me</label>
                                    <Link to={"/forget-password"} className="float-end forgot-password">Forgot Password?</Link>
                                </div>
                                {loading === true ?
                                    <button type="button" className="btn btn-danger w-100" disabled>Loading, Please wait...</button>
                                    :
                                    <button type="submit" className="btn btn-login w-100">Login</button>
                                }
                            </form>
                            <p className="mt-3 text-center">
                                Don't have an account? <Link to="/agentregistration" className="signup-link">Sign up</Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <HomeBanner></HomeBanner>
            <Footer></Footer>
        </div>
    );
}