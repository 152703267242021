import { useEffect, useState } from "react";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import axios from "axios";
import APIConfig, { APIACTIVATEURL } from "../Configuration/APIConfig";
import PageLoader from "../Layout/PageLoader";
export default function Products() {
    const { state } = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [userId, setUserId] = useState("00000000-0000-0000-0000-000000000000");
    const [activities, setActivities] = useState({});
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
        }
    }
    const BindPageData = (searchQuery) => {
        setLoading(true);
        GetProducts(searchQuery);
    }
    const GetProduct = (e, searchQuery) => {
        navigate("/product/" + searchQuery.productId + "/" + params.destinationCode + "/" + params.destination + "/" + params.adults + "/" + params.kids + "/" + params.travelDate, { state: { searchQuery } });
    }
    const GetProducts = (searchQuery) => {
        axios
            .post(APIConfig.APIACTIVATEURL + APIConfig.GETPRODUCTS, searchQuery, { ...headerconfig })
            .then((response) => {
                var responseData = response.data.response;
                if (responseData.succeeded) {
                    console.log(responseData)
                    setActivities(responseData.data.sightSeeingProductResponses);
                    setLoading(false);
                }
                else {
                    setLoading(false);
                }
            }).catch(function (error) {
                setLoading(false);
            });
    };
    useEffect(() => {
        if (localStorage.getItem("userId") !== null) {
            setUserId(localStorage.getItem("userId"))
        }
        BindPageData(state.searchQuery);
    }, []);
    return (
        <div id="main-wrapper">
            {loading === true ? <PageLoader></PageLoader> :
                <div>
                    <Header></Header>
                    <div className="clearfix"></div>
                    <div className="py-2 gray-simple position-relative">
                        <div className="container">
                            {/* Search Form */}
                            <div className="row justify-content-center align-items-center">
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb mb-0">
                                            <li className="breadcrumb-item"><a href="#" className="text-primary">Home</a></li>
                                            <li className="breadcrumb-item active" aria-current="page">Activities</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section class="gray-simple">
                        <div className="container">
                            <div className="row justify-content-between gy-4 gx-xl-4 gx-lg-3 gx-md-3 gx-4">
                                <div className="col-xl-12 col-lg-8 col-md-12">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-xl-4 col-lg-4 col-md-4">
                                            <h5 className="fw-bold fs-6 mb-lg-0 mb-3">Showing {activities.length} Search Results</h5>
                                        </div>
                                    </div>
                                    <div className="row align-items-center g-4 mt-2">
                                        {activities.length > 0 && activities.map(a =>
                                            <div className="col-xl-12 col-lg-12 col-md-12">
                                                <div className="card list-layout-block rounded-3 p-3">
                                                    <div className="row">
                                                        <div className="col-xl-4 col-lg-3 col-md">
                                                            <div className="cardImage__caps rounded-2 overflow-hidden h-100">
                                                                <img className="img-fluid h-100 object-fit" src={a.imageURL} alt="image" style={{ maxHeight: "300px", maxWidth: "300px" }} />
                                                            </div>
                                                        </div>
                                                        <div className="col-xl col-lg col-md">
                                                            <div className="listLayout_midCaps mt-md-0 mt-3 mb-md-0 mb-3">
                                                                <div className="d-flex align-items-center justify-content-start mb-1">
                                                                    <span className="label bg-light-success text-success">{a.destinationName}</span>
                                                                </div>
                                                                <h4 className="fs-5 fw-bold mb-1">{a.productName}</h4>
                                                                <div className="detail ellipsis-container mt-3">
                                                                    <span className="ellipsis bg-light-warning text-danger">Duration : {a.duration} mins</span>
                                                                </div>
                                                                <ul className="row gx-2 p-0 excortio">
                                                                    <p className="text-muted-2 text-md multi-line-text" style={{ maxHeight: "95px" }}>{a.description}</p>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-auto col-lg-auto col-md-auto text-right text-md-left d-flex align-items-start align-items-md-end flex-column">
                                                            <div className="position-relative mt-auto full-width">
                                                                <div className="d-flex align-items-center justify-content-start justify-content-md-end">
                                                                    <div className="text-dark fw-bold fs-3">{a.currencyCode} {a.salePrice}</div>
                                                                </div>
                                                                <div className="d-flex align-items-start align-items-md-end text-start text-md-end flex-column">
                                                                    <button onClick={(e) => GetProduct(e, a)} className="btn btn-md btn-primary full-width fw-medium px-lg-4">View Details<i className="fa-solid fa-arrow-trend-up ms-2" /></button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer></Footer>
                </div>
            }
        </div>
    )
}